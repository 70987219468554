.startCreditAppPage {
  position: relative;
  font-size: 16px;
  left: 0px;
  /* UI Properties */
  background: #FAFAFA 0% 0% no-repeat padding-box;
  opacity: 1;
  font-family: "source-sans-pro";

  > div:not(.modal){
    background: white;
    box-shadow: 0px 8px 24px 0px #00000026;
    border-radius: 8px;
    max-width: 1400px;
    min-width: 870px;
    width: 100%;
    > div{
      margin: 0 auto;
      padding: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      max-width: 960px;
      display: flex;
    }
  }
  .steps{
    display: flex;
    justify-content: space-between;
    margin: 0 12px;
    gap: 30px
  }
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      margin-top: 24px;
      margin-bottom: 16px;
      font-weight: 400;
    }
    p{
      text-align: center;
    }
    img {
      max-width: 256px;
      max-height: 170px;
    }
  }
  .startButton{
    height: 48px;
    padding-top: 16px;
    padding-bottom: 16px;
    margin-top: 30px;
    margin-bottom: 16px;
    font-size: 18px;
  }

  h2 {
    font-size: 40px;
    font-weight: 300;
    line-height: 64px; /* 160% */
  }
  h4{
    font-weight: 300;
    font-size: 24px;
    line-height: 36px;
  }
}
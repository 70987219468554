.notReadyForCreditAppModal {
  .modal-content{
    max-width: 680px;
    top: 300px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  background-color: transparent;
  position: absolute;
}